<template>
  <div class="permission-btn" v-if="show">
    <slot></slot>
  </div>
</template>

<script>
import { USER_INFO } from '@/utils/constant'

export default {
  name: "PermissionBtn",

  props: {
    code: {
      type: [String, Number],
      required: true,
    },
	},
	
	data() {
    return {
			menuList: JSON.parse(window.localStorage.getItem(USER_INFO)).userResourceList
    }
  },

  computed: {
    show() {
			console.log('menuList11:', this.menuList)
			return true

		},
	}
};
</script>
<style lang="scss">
.permission-btn{
	display: inline-block;
}
</style>