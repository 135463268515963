import Cookies from 'js-cookie'

const app = {
    namespaced: true,

    state: {
        sidebar: { opened: true },
        language: 'zh' || Cookies.get('language') || 'en',
        showLangChange: true, // 非修改项，如设为true则开启多语言切换功能，需要安装 i18n ，并将main.js内的相关代码放开注释

        drawer: {
            form: {},
            show: false,
        },
    },

    getters: {
        sidebar: state => state.sidebar,
        language: state => state.language,
        showLangChange: state => state.showLangChange,
        drawer: state => state.drawer
    },

    mutations: {
        TOGGLE_SIDEBAR: (state, data) => {
            state.sidebar.opened = !state.sidebar.opened
        },

        SET_LANGUAGE: (state, language) => {
            state.language = language
            Cookies.set('language', language)
        },

        SET_DRAWER: (state, data) => {
            state.drawer = data
        }
    },

    actions: {
        toggleSideBar: ({ dispatch, commit, state }, data ) => {
            commit('TOGGLE_SIDEBAR')
        },
        
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language)
        },

        setDrawer({ commit }, data) {
            commit('SET_DRAWER', data)
        }
    }
}

export default app