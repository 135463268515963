function picError (el, binding) {
  // 头像图片加载错误 加载默认图
  if (!el.src) {
    el.src = binding.value ? binding.value : 'static/media/pic.png'
  }
  window.$(el).on('error', function () {
    // binding.value值不能是src失败的值,不然会无限循环
    el.src = binding.value ? binding.value : 'static/media/pic.png'
  })
}
export default {
  install: function (Vue) {
    Vue.directive('picError', picError)
  }
}