import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'
import PermissionBtn from '@/components/PermissionBtn'
import '@/assets/css/index.sass'
import directive from '@/directives'

import { PLAT_FORM_NAME } from '@/utils/constant'
import i18n from '@/lang'

Vue.component('permission-btn', PermissionBtn)
import VueTypedJs from 'vue-typed-js'

Vue.use(VueTypedJs)
Vue.use(ElementUI,{
	size: 'medium',    
    i18n: (key, value) => i18n.t(key, value)
})

// 全局注册过滤器
const filtersFiles = require.context('@/filter', true, /\.js$/)
filtersFiles.keys().reduce((filters, filtersPath) => {
  const value = filtersFiles(filtersPath)
  Object.keys(value).forEach(key => {
    Vue.filter(key, value[key])
  })
}, {})

Vue.use(directive)

Vue.config.productionTip = false

window[PLAT_FORM_NAME] = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
  data: {

  }
})