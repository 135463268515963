/**
 * @desc 判断数组是否有效
 * @author xugang
 * @param {Array} arr 目标数组
 * @return Boolean
 */

export const isValidArr = (arr) =>
  !arr ||
  Object.prototype.toString.call(arr) !== "[object Array]" ||
  !arr.length
    ? false
    : true;

/**
 * @desc 数组对象去重
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {String} key 目标属性值
 * @return Array
 */
export const uniqueArrByKey = (arr, key) => {
  if (!isValidArr(arr)) return [];

  var res = [],
    obj = {};
  for (var i = 0; i < arr.length; i++) {
    if (!obj[arr[i][key]]) {
      res.push(arr[i]);
      obj[arr[i][key]] = true;
    }
  }

  return res;
};

/*
 * @desc format数组menu，剔除不显示在左侧导航内的菜单
 * @author xugang
 * @param arr 目标数组
 * @param key 目标属性值
 * @return Array
 */
export const formatMenu = (arr = [], key) => {
  // let arr = JSON.parse(JSON.stringify(menus))
  if (!isValidArr(arr)) return [];
  // arr.forEach((item, index) => {
  // 	if(!item[key]){
  // 		arr.splice(index, 1)
  // 	}
  // 	if(item.children && item.children){
  // 		formatMenu(item.children, key)
  // 	}
  // })

  for (var i = arr.length - 1; i >= 0; i--) {
    if (!arr[i][key]) {
      arr.splice(i, 1);
    } else {
      if (arr[i].children && arr[i].children.length) {
        formatMenu(arr[i].children, key);
      }
    }
  }
  return arr;
};

/*
 * @desc format数组menu，剔除不显示在左侧导航内的菜单
 * @author xugang
 * @param arr 目标数组
 * @param key 目标属性值
 * @return Array
 */
export const formatArr = (arr = []) => {
  arr.forEach(item => {
    if(item.children){
      if(!item.children.length){
        delete item.children
      }else{
        formatArr(item.children)
      }
    }
  })
  return arr
};

/*
 * @desc 根据当前路由code，获取面包屑数组
 * @author xugang
 * @param menus 权限菜单
 * @param code 当前路由code码
 * @return Array
 */
export const findBreadcrumbDataList = (menus = [], code) => {
  let arr = JSON.parse(JSON.stringify(menus))
  if (!isValidArr(arr)) return [];

  let res = [];
  arr.forEach((item) => {
    if (item.code === code) {
      res.push(item);
      return res
    }

    if (item.children && item.children.length) {
      const subs = findBreadcrumbDataList(item.children, code);
      if (subs.length) res = [item, ...subs];
    }
  });
  return res;
};

/*
 * @desc 根据当前路由code，获取当前菜单
 * @author xugang
 * @param menus 权限菜单
 * @param code 当前路由code码
 * @return Array
 */
export const findMenuByCode = (menus = [], code) => {
  let obj = {}, hasFound = false;
  const fn = (arr, value) => {
    if (Array.isArray(arr) && !hasFound) { // 判断是否是数组并且没有的情况下，
      arr.forEach(item => {
         if (item.code === value) { // 数据循环每个子项，并且判断子项下边是否有id值
          obj = item; // 返回的结果等于每一项
          hasFound = true; // 并且找到id值
        } else if (item.children && item.children.length) {
          fn(item.children, value); // 递归调用下边的子项
        }
      })
    }
  }

  fn(menus, code)
  return obj
};

/**
 * @desc 字符串格式 转 驼峰格式
 * @author xugang
 * @param {String} name 组件名
 * @param {Boolean} type 是否为大驼峰 (默认true为大驼峰， false为小驼峰)
 * @returns {String} 符合驼峰格式的名称
 */
export const strFormat1 = (name, type = true) => {
  name = type ? name.substring(0, 1).toUpperCase() + name.substring(1) : name;
  let idx = 0;
  do {
    idx = name.indexOf("-");
    let nextChar = name.substr(idx + 1, 1);
    name = name.replace("-" + nextChar, nextChar.toUpperCase());
  } while (idx > 0);
  return name;
};

/**
 * @desc 数组元素互换位置
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index1 目标索引1
 * @param {Number} index2 目标索引2
 * @returns {Array} 转换后数组 swapArr(arr, index1, index2)
 */
export const swapArr = (arr, index1, index2) => {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
};

/**
 * @desc 数组元素置顶移动
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const toFirst = (arr, index) => {
  if (index !== 0) arr.unshift(arr.splice(index, 1)[0]);
};

/**
 * @desc 数组元素上移动一格
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const moveUp = (arr, index) => {
  if (index !== 0) {
    arr[index] = arr.splice(index - 1, 1, arr[index])[0];
  } else {
    arr.push(arr.shift());
  }
};

/**
 * @desc 数组元素下移动一格
 * @author xugang
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const moveDown = (arr, index) => {
  if (index !== arr.length - 1) {
    arr[index] = arr.splice(index + 1, 1, arr[index])[0];
  } else {
    arr.unshift(arr.splice(index, 1)[0]);
  }
};

/**
 * @desc 对象深拷贝
 * @author xugang
 * @param {Object} originObj 目标对象
 * @returns {Object} 
 */
export const treeObj = (originObj) => {
  let obj = {};
  for (var key in originObj) {
    var val = originObj[key];
    obj[key] = typeof val === "object" ? arguments.callee(val) : val;
  }
  return obj;
}


/**
 * @desc 将对象数组中具有父子关系的数据换成树形结构
 * @author xugang
 * @param {Array} data 带转换成树形结构的对象数组
 * @param {Object} attributes 对象属性,默认为 { id: 'id', parentId: 'parentId' }
 * @returns {Array}
 */
export const toTreeData = (data, attributes = { id: 'id', parentId: 'parentId' }) => {
  if (!isValidArr(data)) return [];

  // let arr = JSON.parse(JSON.stringify(data));
  let arr = data;
  let tree = [];

  // 找寻根节点
  for (let i = 0; i < arr.length; i++) {
    if (!arr[i][attributes.parentId]) {
      tree.push(arr[i]);
      arr.splice(i, 1);
      i--;
    }
	}
	
  run(tree);

  // 找寻子树
  function run(chiArr) {
    if (arr.length) {
      for (let i = 0; i < chiArr.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (chiArr[i][attributes.id] === arr[j][attributes.parentId]) {
            delete chiArr[i].component
						chiArr[i].children = []
						chiArr[i].children.push(arr[j]);
            arr.splice(j, 1);
            j--;
          }
        }
        if(chiArr[i].children && chiArr[i].children.length) run(chiArr[i].children);
      }
    }
  }

  return tree;
}

//判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
export const hasKey = (obj, key) => {
  if (key) return key in obj
  else {
      let keysArr = Object.keys(obj)
      return keysArr.length
  }
}

//判断两个对象是否相等，这两个对象的值只能是数字或字符串
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}

import XLSX from 'xlsx'
/**
 * 将 table 转换成 Excel 导出
 * @param {*} el table 的根 dom 元素
 * @param {*} fileName 下载时文件名称
 */
export const exportExcelByTable = (el, fileName = 'example.xlsx') => {
  if (!el) {
throw new Error('没有获取到表格的根 dom 元素');
 }
const options = { raw: true };
const workbook = XLSX.utils.table_to_book(el, options);

return XLSX.writeFile(workbook, fileName, { type: 'binary' });
};
