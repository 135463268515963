import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import { USER_INFO } from "@/utils/constant";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
NProgress.configure({ showSpinner: false }); // NProgress Configuration

Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  next()
  // let jsonStr = window.localStorage.getItem(USER_INFO);
  // if (jsonStr && JSON.parse(jsonStr).token) {
	// 	next();
	// 	NProgress.done()
  // } else {
  //   // 如果是登录、注册、忘记密码页面路径，就直接next()，否则就跳转至登录页
  //   if (["/auth/login", "/auth/register", "/auth/reset"].includes(to.path)) {
  //     next();
  //   } else {
  //     next("/auth/login");
	// 	}
		NProgress.done()
  // }
});

router.afterEach(({ meta, params, query }) => {
  document.title = !meta || !meta.title ? " " : meta.title;
});

export default router;
