import { strFormat1 } from '@/utils'

let home_config = [
    '/auth/login__& 登录'
]

let user_config = [
    '/home/index__& 系统总览',
    '/operation/index__& 运营中心',
    '/resource/index__& 资源中心',
    '/business/index__& 业务中心',
    '/engineRoom/index__& 机房中心'
]

let routes = [], user_routes = [], relativePath = ''

user_routes.push({
    path: '/',
    component: resolve => require(['../components/Layout/index.vue'], resolve),
    children: parseRoutes(user_config)
})

routes = [...parseRoutes(home_config), ...user_routes]

// 404 页面
routes.push({
    path: '*',
    component: resolve => require(['../view/404.vue'], resolve)
})

// 工厂函数：解析路由配置，返回routes数组
function parseRoutes(config) {
    return [
        ...config.map(item => {
            let array = item.split('__&')
            const fileName = array[0].replace(/\/(\:|\?)[A-z]+$/g, '')
            const fileNameFormatPre = fileName.replace(/\//, '')
            return {
                name: fileNameFormatPre,
                path: relativePath + array[0].replace(/(\/index|home\/index)$/g, ''),
                component: resolve => require([`../view${fileName}.vue`], resolve),
                meta: {
                    title: array[1] && array[1].trim(),
                    code: strFormat1(fileNameFormatPre.split('/').join('-'), false)
                }
            }
        })
    ]
}

export default routes