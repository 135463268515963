export default {
  route: {
    homeIndex: '系统总览',
    homeIndex: '系统总览',
    operationIndex: '运营中心',
    businessIndex: '业务中心',
    resourceIndex: '资源中心',
    engineRoomIndex: '机房中心',
  },
  navbar: {
    logOut: '退出登录',
    reset: '修改密码',
    theme: '换肤'
  },
  tagsView: {
    closeAll: '关闭所有',
    closeOther: '关闭其它'
  },
  project: {
    projectName: '光谷云管理后台'
  }
}